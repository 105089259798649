<template>
    <ass-detail :item="info" v-if="info"></ass-detail>
</template>

<script>
    import AssDetail from "../../layouts/AssDetail";
    export default {
        name: "AssessmentDetail",
        components: {
            AssDetail
        },
        data() {
            return {
                info: null
            }
        },
        created() {
            this.getAssessment();
        },
        methods: {
            getAssessment() {
                let detail = this.$store.getters.detail;
                let id = this.id;
                if(detail && detail.type == 'ass' && detail.obj.id == id) {
                    this.dealInfo(detail.obj)
                } else {
                    this.getDetail();
                }
            },
            getDetail() {
                let url = `/admin/evaluate-item/${this.$route.params.id}?expand=message`;
                this.$axios(url).then(this.dealInfo);
            },
            dealInfo(info) {
                this.info = info;
            }
        }
    }
</script>

<style scoped>

</style>
